export default defineI18nConfig(() => ({
  legacy: false,
  pluralRules: {
    sk: (choice: number) => {
      if (choice >= 5) return 3
      if (choice >= 2) return 2

      return choice === 0 ? 0 : 1
    },
  },
}))
